<template>
<footer class="">
    <div class="mx-auto max-w-screen-xl p-4">
        <hr class="my-6 border-black sm:mx-auto lg:my-8" />
        <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
                <LogoView/>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
                <div>
                    <h2 class="mb-6 text-normal font-semibold text-gray-900 uppercase">Follow us</h2>
                    <ul class="text-gray-600">
                        <li class="mb-4"> <a href="https://twitter.com/scaleuprobotics" class="hover:underline">Twitter</a> </li>
                    </ul>
                    <ul class="text-gray-600">
                        <li class="mb-4"> <a href="https://www.linkedin.com/company/scaleup-robotics/" class="hover:underline">LinkedIn</a> </li>
                    </ul>
                </div>
            </div>
        </div>

        <span class="pt-16 flex justify-center text-lg sm:text-center">
            © 2024 Scaleup Robotics AB. All Rights Reserved. Made with ♥ in Stockholm, Sweden.
        </span>
    </div>
</footer>
</template>
